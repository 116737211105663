import(/* webpackMode: "eager" */ "/vercel/path0/hosting/app/components/analytics_scripts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/hosting/app/components/head_scripts.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/hosting/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/hosting/app/theme.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/hosting/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/hosting/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/hosting/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/hosting/node_modules/@vercel/speed-insights/dist/next/index.mjs");
